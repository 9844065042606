html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  /* Don't forget about fallback font/family */
}

.visibility-hidden {
  visibility: hidden;
  opacity: 0;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

#onetrust-banner-sdk {
  display: none !important;
}

form {
  text-align: center;
}

span.rc-input--inline.rc-input--label.rc-input {
  max-width: 730px;
  width: 100%;
  margin-inline-end: 0;
  margin-block-start: 0;
}

.quiz__button {
  margin-top: 40px;
}

.rc-input__label {
  text-align: left;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.visually-hidden {
  visibility: hidden;
}

.rc-header--quiz-promo {
  border: 1px solid #f1f1f1;
}

.custom-quiz-promo__open-modal-btn {
  margin-block-end: 16px;
}

.custom-quiz-promo__gifts {
  font-size: 10px;
  line-height: 16px;
}

.custom-quiz-promo__gifts-subscription {
  margin: 0;
  font-weight: 400;
}

.custom-quiz-promo__gifts-list {
  padding-inline-start: 20px;
}

.custom-quiz-promo__gifts-list:not(.rc-list--blank):not(.rc-list--inline) li.rc-list__item {
  margin-block-end: 0;
}

.quiz__error-helper {
  display: none;
  visibility: hidden;
}

.rc-input--error .quiz__error-helper {
  display: inline-block;
  visibility: visible;
  position: absolute;
  inset-block-end: -14px;
  inset-inline-start: 0;
  font-size: 10px;
}

.quiz__radio-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  max-width: 462px;
  margin: 0 auto;
  margin-block-start: 2.25rem;
}

span.rc-input.quiz__file-input {
  max-width: unset;
}

.quiz__file-input input.quiz__upload-file-input {
  display: none;
  visibility: hidden;
}

.quiz__remove-file {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.quiz__wrapper .rc-input__textarea {
  height: 100px;
}

.custom-quiz-promo__winners-section ul {
  list-style: none;
}

.quiz-form .rc-select.rc-input {
  border: none;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-inline-end: 0;
}

.quiz-form .rc-select.rc-input::after {
  background: none;
}

.quiz-form__thx-text {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  padding-inline-start: 32px;
}

.quiz-form__thx-text::before {
  content: "";
  background-image: url(../../assets/img/ic-check.svg);
  width: 24px;
  height: 32px;
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.quiz-form .rc-input__label-text,
.quiz-form .rc-select__label {
  text-indent: 0;
}

.quiz-form .focus-visible ~ .rc-input__label .rc-input__label-text {
  font-weight: 300;
}

div#mars-footer-panel.mars-footer-mars .mars-footer-legal,
.mars-footer-legal p:last-of-type {
  margin-block-end: 0;
}

.quiz__wrapper .rc-gamma {
  margin-block-end: 32px;
}

.custom-quiz-promo__winners-list,
.custom-quiz-promo__main-prise-winners-list {
  display: grid;
  justify-content: center;
}

.pika-lendar .pika-title select {
  position: static;
  filter: alpha(opacity=1);
  opacity: 1;
  appearance: none;
  background-color: transparent;
  border: none;
  font-family: inherit;
  color: #666;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.pika-lendar .pika-label {
  font-size: 0;
  line-height: 10px;
}

.pika-lendar .pika-next,
.pika-lendar .pika-prev {
  top: 5px;
}

button.is-uploading {
  background-color: #d7d7d7;
  pointer-events: none;
  border: none;
}

.quiz__wrapper .choices__list--dropdown {
  z-index: 9999999 !important;
}

.quiz-modal .rc-modal__content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quiz-form {
  width: 100%;
}

.rc-modal .rc-modal__container.quiz-modal__container {
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.quiz__wrapper .rc-input--textarea .rc-input__label-text {
  white-space: unset;
}

.choices__input.choices__input--cloned {
  pointer-events: auto !important;
}

.flatpickr-input {
  text-align: left;
}

.flatpickr-day.selected,
.flatpickr-day.selected:hover {
  background-color: #e2001a;
  border-color: #e2001a;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  color: #666;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

span.flatpickr-weekday {
  color: #666;
  font-weight: 500;
  font-size: 0.875rem;
}

.flatpickr-day {
  color: #666;
  font-weight: 300;
  font-size: 0.875rem;
}

.flatpickr-day:hover {
  background-color: #e2001a;
  border-color: #e2001a;
  color: #fff;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #e2001a;
}

.quiz__wrapper .rc-icon.rc-calendar--xs::after {
  z-index: -1;
}

.quiz__wrapper .choices select.is-focused,
.quiz__wrapper .rc-select .choices.is-focused {
  outline: none;
}

#id-post-input {
  appearance: none;
}

#id-post-input::-webkit-outer-spin-button,
#id-post-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quiz-form__thx-helper-text--have-pet,
.quiz-form__thx-helper-text--dont-have-pet {
  max-width: 70%;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

@media (max-width: 768px) {
  .custom-quiz-promo__promo-section .rc-layout-container .rc-column:first-child {
    order: 1;
  }
}
@media (max-width: 576px) {
  .quiz-modal__container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .quiz-section {
    width: 100%;
    z-index: 99;
  }

  .quiz__wrapper .rc-btn--two {
    float: left;
  }

  .quiz__wrapper .rc-btn--two + .rc-btn--one,
.quiz__button--dont-have-pet {
    float: right;
  }

  .quiz__radio-container {
    flex-direction: column;
    align-items: start;
  }

  .quiz-form {
    width: 100%;
    transform: translateY(-2rem);
  }

  .rc-modal .rc-modal__container.quiz-modal__container {
    max-height: 100vh;
  }

  .rc-modal .rc-modal__container.quiz-modal__container .rc-modal__header {
    width: auto;
    height: auto;
    right: 0;
    top: 10px;
    background-color: transparent;
    z-index: -1;
  }

  .rc-modal .rc-modal__container.quiz-modal__container rc-modal__close {
    padding-inline-end: 0.5rem;
    padding-block-start: 2px;
    padding-block-end: 2px;
  }

  .quiz-form__thx-helper-text--have-pet,
.quiz-form__thx-helper-text--dont-have-pet {
    max-width: 90%;
  }
}
@media (max-height: 760px) {
  .quiz-form {
    transform: translateY(0);
  }
}
@media (max-width: 480px) {
  .custom-quiz-promo__promo-content-wrap {
    display: grid;
  }

  .custom-quiz-promo__promo-content-wrap .custom-quiz-promo__open-modal-btn {
    justify-self: center;
  }

  .week-winners-title,
.main-winners-title {
    text-align: start;
  }

  .rc-alpha.main-winners-title {
    margin-block-start: 30px;
  }

  .custom-quiz-promo__winners-list,
.custom-quiz-promo__main-prise-winners-list {
    justify-content: start;
    padding-inline-start: 0;
  }

  div.custom-quiz-promo__more-section {
    padding-block-end: 38px;
  }

  .custom-quiz-promo__more-section .rc-btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .quiz-form__thx-text::before {
    inset-block-start: -2px;
    transform: none;
  }
}