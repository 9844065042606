//$transition_base: ease-in-out 0.3s;

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  //outline: 1px solid;
}

body {
  //background-color: $white_color;
  //color: $black_color;
  //margin: 0;
  //padding: 0;
  //font-family: 'Gilroy', sans-serif;
  /* Don't forget about fallback font/family */
  //font-size: 20px;
  //line-height: normal;
  //min-height: 100vh;
}

//.logo {
//  display: inline-block;
//  height: auto;
//}

//.logo > img {
//  object-fit: contain;
//}

//img {
//  max-width: 100%;
//  height: auto;
//}

.visibility-hidden {
  visibility: hidden;
  opacity: 0;
}

@import 'modules/a11y';
//@import 'modules/grid';
@import './cusom-styles';
